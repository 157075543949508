import axios from "./request"
// 获取收货地址
export const getAddressList = (data) => {
    return axios({
        url: "/api/member/getMyAddressList",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}

// 保存收货地址
export const saveAddress = (data) => {
    return axios({
        url: "/api/member/saveMyAddress",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}

// 删除收货地址
export const deleteAddress = (data) => {
    return axios({
        url: "/api/member/deleteMyAddress",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}

// 地址详情
export const addressDetail = (data) => {
    return axios({
        url: "/api/weixin/mall/addressDetail",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}