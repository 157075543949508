<template>
  <div class="flex flex-column addresslist">
    <ul class="addresslist-ul" v-if="addressList.length > 0">
      <li class="addresslist-ul-li" v-for="item in addressList" :key="item.id">
        <div class="addresslist-ul-li-info" @click="selectAddressFunc(item)">
          <div class="addresslist-ul-li-info-user">
            <span>{{item.addressCustomerName}}</span>
            {{item.addressMobilePhone}}
          </div>
          <div class="addresslist-ul-li-info-area">
            <span v-if="item.defaultSelect == 1">默认</span>{{item.addressCity}}&nbsp;&nbsp;&nbsp;&nbsp;{{item.addressArea}}
          </div>
        </div>
        <div class="flex flex-right addresslist-ul-li-btn">
          <div class="flex flex-right align-center addresslist-ul-li-btn-list" @click="gotoAddressPagesFunc(item.addressId)">
            <img src="../../../assets/img/order/edit.png" alt="">
            编辑
          </div>
          <div v-if="platfrom != 'order'" class="flex flex-right align-center  addresslist-ul-li-btn-list" @click="deleteAddressFunc(item.addressId)">
            <img src="../../../assets/img/order/delete.png" alt="">
            删除
          </div>
        </div>
      </li>
    </ul>
    <!-- 没有数据 -->
    <div class="addresslist-nodata" v-if="addressList.length == 0">
      <div class="addresslist-nodata-main">
        <div class="flex flex-center flex-wrap addresslist-nodata-main-div">
          <img src="../../../assets/img/order/address-nodata.png" alt="">
          <div>暂无收货地址</div>
        </div>
      </div>
    </div>
    <!-- 提交按钮 -->
    <van-popup v-model:show="isPopup" position="bottom" round safe-area-inset-bottom :overlay="false" :lock-scroll="false">
      <div class="addresslist-btn">
        <van-button color="#E62129" round size="large" @click="gotoAddressPagesFunc('')">新增收货地址</van-button>
      </div>
    </van-popup>
  </div>
</template>
<script src="./index.js"></script>
<style lang="scss" scoped>
  @import "./index.scss";
</style>