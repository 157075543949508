import {
    getAddressList,
    deleteAddress
} from '@/utils/address.js';
export default {
    data() {
        return {
            isPopup: true,
            addressList: [],
            platfrom: ''
        }
    },
    created() {
        this.platfrom = this.$route.query.platfrom;
        this.getAddressListFunc();
    },
    methods: {
        // 获取地址列表
        getAddressListFunc() {
            let data = {
                memberId: this.$cookies.get('memberId')
            }
            getAddressList(data).then(res => {
                console.log('地址', res.data)
                if (res.code == 0) {
                    this.addressList = res.data.addressList ? res.data.addressList : [];
                } else {
                    this.$toast({
                        message: res.message
                    })
                }
            })
        },
        // 删除收货地址
        deleteAddressFunc(id) {
            this.$dialog.confirm({
                    title: '提示',
                    message: '确认删除该收货地址',
                })
                .then(() => {
                    let data = {
                        memberId: this.$cookies.get('memberId'),
                        addressId: id
                    }
                    // 判断用户选择的地址 是否是当前删除的地址
                    let addressInfo = JSON.parse(sessionStorage.getItem('addressInfo'));
                    if (addressInfo.addressId == id) {
                        sessionStorage.removeItem('addressInfo');
                    }
                    
                    deleteAddress(data).then(res => {
                        console.log('删除', res)
                        if (res.code == 0) {
                            this.$toast({
                                message: '地址删除成功！',
                                forbidClick: true,
                                type: 'success'
                            })
                            this.getAddressListFunc();
                        }
                    })
                })
                .catch(() => {
                    // on cancel
                });
        },
        // 跳转到新增地址页面
        gotoAddressPagesFunc(addressId) {
            this.$router.push({
                path: '/addAddress',
                query: {
                    addressId: addressId
                }
            })
        },
        // 选择地址
        selectAddressFunc(row) {
            console.log('row', {...row })
            if (this.platfrom === 'order') {
                sessionStorage.setItem('addressInfo', JSON.stringify({...row }));
                this.$router.go(-1);
            } else {
                return false;
            }
        }
    },
}